import React, { Suspense, useEffect } from 'react'
import { HashRouter, Route, Routes, Navigate } from 'react-router-dom'
import './scss/style.scss'
import './styles.css'
import { useAuth } from './AuthContext' // Import the AuthContext for authentication
import Auth from './Services/Helpers/Auth'
import { GoogleOAuthProvider } from '@react-oauth/google'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
const Userform = React.lazy(() => import('./components/UserForm'))
const Otp = React.lazy(() => import('./views/pages/login/Otp'))
const Forgotpassword = React.lazy(() => import('./views/pages/login/Forgotpassword'))
const NewPassword = React.lazy(() => import('./views/pages/login/NewPassword'))
const ContactUs = React.lazy(() => import('./views/pages/contactUs/contactUs'))

function App(status, module_id) {
  status = status == undefined ? [] : status
  module_id = module_id == undefined ? [] : module_id

  return (
    <GoogleOAuthProvider clientId="601120152173-1ak337930pdl10eteqadhffoupupc1cn.apps.googleusercontent.com">
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/404" element={<Page404 />} />
            <Route path="/500" element={<Page500 />} />
            <Route
              path="*"
              element={Auth.isAuthenticated ? <DefaultLayout /> : <Navigate to="/" />}
            />
            <Route path="/userform" element={<Userform />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="/forgot-password" element={<Forgotpassword />} />
            <Route path="/new-password" element={<NewPassword />} />
            <Route path="/contact" element={<ContactUs />} />
            <Route path="*" name="Home" element={<DefaultLayout />} />
          </Routes>
        </Suspense>
      </HashRouter>
    </GoogleOAuthProvider>
  )
}

export default App