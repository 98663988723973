// Auth.js
class Auth {
  constructor() {
    this.isAuthenticated = true;
    this.checkAuthentication();
  }

  checkAuthentication() {
    return new Promise((resolve) => {
      const userString = localStorage.getItem('user');
      if (userString) {
        const userObject = JSON.parse(userString);
        this.isAuthenticated = true;
      }  
      resolve(); // Resolve the promise after setting isAuthenticated
    });
  }

  isAuthenticatedFun() {
    if (this.isAuthenticated === true) {
      return this.isAuthenticated;
    } else {
      window.location.href = '/';
      return false
    }
  }
}

export default new Auth();
